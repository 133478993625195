import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ArrowIconRight from '../../components/SVG/Icons/IconArrowRightCircle'
import ArrowIconLeft from '../../components/SVG/Icons/IconArrowLeftCircle'

// How to conditionally wrap an element in React
// https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2

const ConditionalWrapper = ({
  condition,
  linkWrapper,
  buttonWrapper,
  children,
}) => (condition === true ? linkWrapper(children) : buttonWrapper(children))

const ArrowLink = ({
  onClick,
  link,
  linkText,
  largeLink,
  textColor,
  arrowColor,
  arrowDirection,
}) => {
  const linkDefined = link ? true : false
  return (
    <ConditionalWrapper
      condition={linkDefined}
      linkWrapper={children => (
        <AniLink fade to={`/${link}`}>
          {children}
        </AniLink>
      )}
      buttonWrapper={children => (
        <button className="focus:outline-none" onClick={onClick}>
          {children}
        </button>
      )}
    >
      <div
        className={`uppercase tracking-widest arrow-link ${
          arrowDirection === 'left' ? 'arrow-link--left' : 'arrow-link--right'
        } ${largeLink ? 'text-sm font-semibold' : 'text-xs'}`}
      >
        <div
          className={`flex items-center${
            arrowDirection === 'left' ? ' flex-row-reverse' : ''
          }`}
        >
          <div
            className={`arrow-link__text${
              textColor ? ' text-' + textColor : ''
            }`}
          >
            {linkText}
          </div>
          <div
            className={`w-4 h-4 lg:w-6 lg:h-6 inline-block${
              arrowColor ? ' text-' + arrowColor : ''
            }${
              largeLink
                ? arrowDirection === 'right'
                  ? ' ml-4'
                  : ' mr-4'
                : arrowDirection === 'right'
                ? ' ml-3'
                : ' mr-3'
            }`}
          >
            {arrowDirection === 'right' ? (
              <ArrowIconRight />
            ) : (
              <ArrowIconLeft />
            )}
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  )
}

export default ArrowLink
