import React, { useState, useEffect, useCallback } from 'react'
import Swiper from 'react-id-swiper'

import ArrowLink from '../Ui/ArrowLink'

const CatchSlider = ({ items }) => {
  const hasSlides = items.length > 1
  // Reference can be found here:
  // https://codesandbox.io/s/p8j61y7j7

  // Swiper instance
  const [swiper, updateSwiper] = useState(null)
  // Slides current index
  const [currentIndex, updateCurrentIndex] = useState(0)
  // Params definition
  const params = {
    loop: true,
    grabCursor: true,
    pagination: false,
    speed: 425,
    preloadImages: true,
    getSwiper: updateSwiper, // Get swiper instance callback
    allowSlidePrev: hasSlides,
    allowSlideNext: hasSlides,
  }

  // Manipulate swiper from outside
  const goNext = () => {
    if (swiper !== null && !swiper.animating) {
      swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper !== null && !swiper.animating) {
      swiper.slidePrev()
    }
  }

  const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [
    swiper,
  ])

  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', updateIndex)
    }
    return () => {
      if (swiper !== null) {
        swiper.off('slideChange', updateIndex)
      }
    }
  }, [swiper, updateIndex])

  return (
    <div>
      <div className="text-center">
        <h1 className="type-upper-xs leading-tight mb-4 sm:mb-8 lg:mb-12">
          Our Catch
        </h1>
      </div>
      <div className="fish-swiper mb-4 sm:mb-8 lg:-mt-4">
        <Swiper {...params}>
          {items.map((item, index) => (
            <div className="w-full" key={index}>
              <div className="gutters">
                <div className="lg:w-18/24 mx-auto relative lg:pt-14">
                  <div className="fish-swiper__image z-20">
                    <div className="absolute inset-0">
                      <img
                        className="w-full h-full object-contain"
                        src={item.image.url}
                        alt={item.image.alt}
                      />
                    </div>
                  </div>
                  <div className="lg:absolute top-0 inset-x-0 text-center mt-4 sm:mt-8 lg:mt-0 z-10">
                    <h3 className="fish-swiper__title lg:pt-4">{item.name}</h3>
                  </div>
                </div>
                <h5 className="italic font-medium tracking-wide text-sm text-center mt-2 sm:mt-6 lg:mt-12">
                  {item.binomial_name}
                </h5>
              </div>
            </div>
          ))}
        </Swiper>
      </div>
      {hasSlides && (
        <div className="gutters border-t border-white-opaque-25">
          <div className="lg:w-20/24 h-12 mx-auto flex">
            <div className="w-9/20 h-full flex items-center">
              <ArrowLink
                linkText="Prev"
                onClick={goPrev}
                textColor="blue-700"
                arrowDirection="left"
                arrowColor="white"
              />
            </div>
            <div className="w-2/20 h-full flex items-center justify-center">
              <div className="text-center text-sm uppercase tracking-widest font-semibold">
                <span className="mr-1">{currentIndex + 1}</span>
                <span className="text-white-opaque-50">/{items.length}</span>
              </div>
            </div>
            <div className="w-9/20 h-full flex items-center justify-end">
              <ArrowLink
                linkText="Next"
                onClick={goNext}
                textColor="blue-700"
                arrowDirection="right"
                arrowColor="white"
              />
            </div>
          </div>
        </div>
      )}
      <div className="lg:gutters border-t border-b border-white-opaque-25">
        <div className="lg:w-16/24 flex mx-auto lg:border-l border-white-opaque-25">
          <div className="w-1/2 border-r border-white-opaque-25 text-center pt-6 pb-4 px-2 sm:pt-10 sm:pb-8 sm:px-16">
            <h3 className="type-upper-xs leading-tight mb-3">Average Weight</h3>
            <p className="text-lg sm:text-6xl font-light uppercase">
              {items[currentIndex].weight}
            </p>
          </div>
          <div className="w-1/2 lg:border-r border-white-opaque-25 text-center pt-6 pb-4 px-2 sm:pt-10 sm:pb-8 sm:px-16">
            <h3 className="type-upper-xs leading-tight mb-3">
              Cooking Features
            </h3>
            <p className="text-base sm:text-2xl font-light">
              {items[currentIndex].features}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CatchSlider
