import React from 'react'

const IconArrowLeftCircle = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 23 23">
      <path d="M11.5,23C5.2,23,0,17.8,0,11.5S5.2,0,11.5,0S23,5.2,23,11.5S17.8,23,11.5,23z M11.5,1C5.7,1,1,5.7,1,11.5S5.7,22,11.5,22	S22,17.3,22,11.5S17.3,1,11.5,1z M13.2,16.9l-5.8-5.4l5.8-5.3l0.7,0.7l-5,4.6l5,4.7L13.2,16.9z" />
    </svg>
  )
}

export default IconArrowLeftCircle
