import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'
import CatchSlider from '../components/Catch/CatchSlider'
import ContentBlocksCallToAction from '../components/ContentBlocks/ContentBlocksCallToAction'

const footerLinks = ['traceability', 'the-kawatea', 'past']

const OurCatchPage = ({ location, data }) => {
  const page = data.prismicCatch.data
  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <div className="pt-hh+ sm:pt-hh++">
        <CatchSlider items={page.fish_items} />
        <ContentBlocksCallToAction
          text={{
            html: page.cta_text.html,
          }}
          link={page.cta_link.uid}
          linkText={page.cta_link_text}
          ghost
        />
      </div>
      <Footer links={footerLinks} />
    </Layout>
  )
}

OurCatchPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageCatchQuery = graphql`
  query {
    prismicCatch {
      data {
        cta_link {
          uid
        }
        cta_text {
          html
        }
        cta_link_text
        meta_title
        meta_description
        fish_items {
          name
          binomial_name
          weight
          features
          image {
            alt
            url
          }
        }
      }
    }
  }
`

export default OurCatchPage
