import React from 'react'

const IconArrowRightCircle = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 23 23">
      <path d="m9.64 16.55-.64-.74 4.95-4.53-4.95-4.66.69-.73 5.74 5.4zm13.36-5.05a11.5 11.5 0 1 0 -11.5 11.5 11.51 11.51 0 0 0 11.5-11.5zm-1 0a10.5 10.5 0 1 1 -10.5-10.5 10.51 10.51 0 0 1 10.5 10.5z" />
    </svg>
  )
}

export default IconArrowRightCircle
